<template>
  <div class="checkselect col-12">
    <div class="checkselect-control" @click.stop="visible=!visible;$emit('opened', {opened: visible, name: name})">
      <div ref="type_popup" :style="visible ? 'display: none' :  'display: block'" class="chech-img"></div>
      <img @click.stop="visible=!visible;$emit('opened', {opened: visible, name: name})" ref="type_popup" 
        :style="visible ? 'display: block' : 'display: none'" src="images/interface/main_catalog/btn-close.png" alt="" class="close-filter">
      <select :style="visible ? 'background: #232176; color:#FFF;' : 'background: #FFF; color: #656565;'"  class="form-control">
        <option>{{displayName}}</option>
      </select>
      <div class="checkselect-over"></div>
    </div>
    <div @click.stop class="checkselect-popup" ref="type_popup"
         :style="visible ? 'display: block' : 'display: none'">
      <div class="glass-help">
        <div class="glass-help-top"></div>
          <label v-for="f in filters" :key="f">
            <input v-on:input="onTypeSelected($event.target.value, $event.target.checked)"
              type="checkbox" :checked="selectedFilters.contains(f)" :value="f">{{f}}
          </label>
        <div class="glass-help-bottom"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {addParamsToLocation, safeParseJSON} from "@/utils/url";

export default {
  name: 'TagsFilter',
  props: ['displayName', 'name', 'selectedFilters', 'filters'],
  data() {
    return {
      visible: false,
    }
  },
  mounted() {
    window.addEventListener('click',() => {
      this.visible=false
    }, false)
  },
  watch: {
    // Следим за изменениями даты для поиска и добавляем ее в квери адресса
    // И наоборот, синхроним дату с изменением квери в адрессе
    // Чтобы работал поиск по ссылке и история
    selectedFilters(value) {
      const p = {}
      p[this.name] = value && value.length ? JSON.stringify(value) : ''
      addParamsToLocation(p, this.$route)
    },
    '$route.query'(v) {
      const filters = safeParseJSON(v[this.name] ?? "") ?? []
      if (!this.selectedFilters.equals(filters)) {
        this.$emit('update:selectedFilters', filters)
      }
    },
  },
  methods: {
    onTypeSelected(type, checked) {
      if (checked && !this.selectedFilters.contains(type)) {
        this.$emit('update:selectedFilters', [type, ...this.selectedFilters])
      }
      else if (!checked && this.selectedFilters.contains(type)) {
        this.$emit('update:selectedFilters', this.selectedFilters.filter(e => e !== type))
      }
      this.$emit('filtersChanged');
    },
  }
}
</script>
