export const addParamsToLocation = (params, route, mode) => {
  let p = route.query ?? {};
  Object.assign(p, params);
  const queryStr = Object.keys(p)
      .filter(key => {
        return p[key].toString().length !== 0
      })
      .map(key => {
        return (
          encodeURIComponent(key) + '=' + encodeURIComponent(p[key].toString())
        )
      })
      .join('&')
  history.replaceState(
    {},
    null,
      (mode === "history" ? "" :"#")+ route.path + (queryStr.length !== 0 ? '?' + queryStr : '')
  )
}


export const safeParseJSON = (json)  => {
  let parsed;

  try {
    parsed = JSON.parse(json)
  } catch (e) {
    // pass
  }
  return parsed
}
