<template>
  <div>
    <!-- <ExpoHeader></ExpoHeader> -->
    <div class="container main-catalog">
        <div class="catalog-button--new">
          <a href="https://mrexpo.ru/assets/img/kataloglight.pdf" target="_blank" class="save-catalog catalog-new">
            <img src="/images/interface/main_catalog/save.svg" alt="">
            <span>{{$t('catalog.save-btn')}}</span>
          </a>
        <router-link to="/studio-catalog" class="studio-btn catalog-new">
            <img src="/images/interface/main_catalog/prod.svg" alt="">
            <span>{{$t('catalog.studio-btn')}}</span>
          </router-link>
        </div>
        <div class="intro-catalog row row-cols-1 row-cols-sm-1 row-cols-lg-1 row-cols-xxl-2">
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xxl-8">
                <span>{{$t('catalog.h1-1')}}<br> {{$t('catalog.h1-2')}} </span>
            </div>
            <div class="col-4 col-sm-6 col-md-7 col-lg-5 col-xxl-4">
                <span>{{$t('catalog.text1')}}</span>
            </div>
        </div>
        <router-link to="/expo" v-bind:class="cssClass2">
          <span>{{$t('catalog.back')}}</span>
        </router-link>
        <div class="main-catalog--search">
            <input type="text" :placeholder="$t('catalog.search-placeholder')" v-model="searchName" >
            <img src ="/images/interface/main_catalog/search.png" class="icon-search" aria-hidden="true">
        </div>
        <div class="main-catalog--checkbox row row-cols-2">
            <div class="col-12 col-sm-12 col-lg-12 col-xxl-8">
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 row-cols-xxl-3 flex-wrap">
                  <TagsFilter
                      @opened="filterOpened"
                      @filtersChanged="page=1"
                      class="filter_2"
                      name="category"
                      :displayName = "$t('catalog.filter-2')"
                      ref="categoryFilter"
                      :selected-filters.sync="selectedCategory"
                      :filters="categories"/>
                  <TagsFilter
                      @opened="filterOpened"
                      @filtersChanged="page=1"
                      class="filter_1"
                      name="type"
                      :displayName = "$t('catalog.filter-1')"
                      ref="typeFilter"
                      :selected-filters.sync="selectedTypes"
                      :filters="types"/>
                  <div class="w-100 d-block d-lg-none"></div>
                  <TagsFilter
                      @opened="filterOpened"
                      @filtersChanged="page=1"
                      class="filter_3"
                      name="manufacturer"
                      ref="manufacturerFilter"
                      :displayName = "$t('catalog.filter-3')"
                      :selected-filters.sync="selectedManufacturer"
                      :filters="manufacturers"/>
                  <TagsFilter
                      @opened="filterOpened"
                      @filtersChanged="page=1"
                      class="filter_4"
                      name="country"
                      ref="countryFilter"
                      :displayName = "$t('catalog.filter-4')"
                      :selected-filters.sync="selectedCountry"
                      :filters="countries"/>
                </div>
            </div>
            <div class="col-4"></div>
        </div>
        <div class="main-catalog--checklist">
            <label :key="t" v-for="t in allSelectedTags" class="checklist-value">
                <span>{{t}}</span>
                <img src="images/interface/main_catalog/button-close.png" alt="" @click="removeTag(t)">
            </label>
        </div>
        <!-- <ExpoExpoCatalogContent>
        </ExpoExpoCatalogContent> -->
        <div class="main-catalog--content">
            <div ref="anchor" class="__anchor"></div>
            <div class="row row row-cols-1 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 row-cols-xxl-6">
              <div class="col item-row item-row-1" v-for="o in paginatedObjects" :key="o.id">
                    <router-link :to="{ name: 'ObjectDetails', params: {id: o.id, objectId: o.objectId, fromCatalog: true}}">
                      <div class="item-row--img item-row--img-1">
                          <img :src="thumb(o.img1)" alt="">
                      </div>
                      <div class="item-row--from item-row--from-1">
                          <span class="item-row-link">{{o.manufacturer}}</span>
                      </div>
                      <div class="item-row--name item-row--name-1">
                          <span class="item-row-link"> {{o.name}}</span>
                      </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="catalog-button--new catalog-mobile--new">
          <div class="save-catalog catalog-new">
            <img src="/images/interface/main_catalog/save.svg" alt="">
            <span>{{$t('catalog.save-btn')}}</span>
          </div>
          <!--<router-link to="/studio-catalog" class="studio-btn catalog-new">
            <img src="/images/interface/main_catalog/prod.svg" alt="">
            <span>{{$t('catalog.studio-btn')}}</span>
          </router-link>-->
        </div>
        <paginate
            ref="pag" v-model="page"
            :page-count="Math.round(objects.length / paginationOptions.chunk)"
            :prev-class="'pag-arrow'"
            :next-class="'pag-arrow'"
            :prev-text="'&#8592;'"
            :next-text="'&#8594;'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :hide-prev-next="true"
        />
    </div>
    <ExpoFooter></ExpoFooter>
  </div>
</template>
<script>
import store from "@/store";
import TagsFilter from "@/components/TagsFilter";
import {addParamsToLocation, safeParseJSON} from "@/utils/url";
import {imop, tr} from "@/utils";
import Paginate from 'vuejs-paginate'
// import ExpoHeader from "@/components/ExpoHeader";
import ExpoFooter from "@/components/ExpoFooter";

export default {
  name: "ExpoCatalog",
  components: {
    TagsFilter,
    Paginate,
    // ExpoHeader,
    ExpoFooter,
    },
  data() {
    return {
      searchName: this.$route.query.name ?? "",
      selectedTypes: safeParseJSON(this.$route.query.type ?? "[]") ?? [],
      selectedCategory: safeParseJSON(this.$route.query.category ?? "[]") ?? [],
      selectedManufacturer: safeParseJSON(this.$route.query.manufacturer ?? "[]") ?? [],
      selectedCountry: safeParseJSON(this.$route.query.country ?? "[]") ?? [],
      page: parseInt(this.$route.query.page ?? "1"),
      paginationOptions: {
        chunk: window.innerWidth <1400 ? 28 : window.innerWidth < 1200 ? 27 : window.innerWidth < 576 ? 20 :30,
      }
    }
  },
  mounted() {

    window.addEventListener('click',() => {
      let chekbox = document.querySelectorAll('.checkselect-popup');
      let closeFilter = document.querySelectorAll('.close-filter')
      let chechImg = document.querySelectorAll('.chech-img')
      let formControl = document.querySelectorAll('.form-control')
      if(chekbox.length > 0 && closeFilter.length > 0){
        // console.log(chekbox)
        chekbox.forEach((item) => {
            // console.log("Ghbdtn cwer")
            if(getComputedStyle(item).display == "block"){
              item.style.display = "none";
            }
        });
        closeFilter.forEach((item) => {
            // console.log("Ghbdtn cwer")
            if(getComputedStyle(item).display == "block"){
              item.style.display = "none";
            }
        });
        chechImg.forEach((item) => {
            // console.log("Ghbdtn cwer")
            if(getComputedStyle(item).display == "none"){
              item.style.display = "block";
            }
        });
        formControl.forEach((item) => {
            // console.log("Ghbdtn cwer")
            if(getComputedStyle(item).color == "rgb(255, 255, 255)"){
              item.style.color = "#656565";
              item.style.background = "#FFF";
            }
        });
      }
    }, false)
  },
  watch: {
      // Следим за изменениями даты для поиска и добавляем ее в квери адресса
      // И наоборот, синхроним дату с изменением квери в адрессе
      // Чтобы работал поиск по ссылке и история
      searchName(value) {
          addParamsToLocation({'name': value}, this.$route)
      },
      '$route.query.name'(v) {
          this.searchName = v ?? ''
      },
      page(value) {
        addParamsToLocation({'page': value}, this.$route);
        this.$refs.anchor.scrollIntoView();
      },
      '$route.query.page'(v) {
          this.page = parseInt(v ?? "1")
      },
  },
  methods: {
    thumb(url) {
      return imop(url, 'c_thumb,h_480');
    },
    swipe(event) {
      switch (event.type) {
          case "swipeleft":
          {
            if ((this.page - 1) * this.paginationOptions.chunk + this.paginationOptions.chunk <= this.objects.length) {
              this.page++;
            }
            break;
          }
          case "swiperight":
          {
            if (this.page > 1) {
              this.page--;
            }
            break;
          }
      }
    },
    filterOpened(data) {
      if (!data.opened)
        return;
      this.$refs.typeFilter.visible = (data.name === this.$refs.typeFilter.name);
      this.$refs.categoryFilter.visible = (data.name === this.$refs.categoryFilter.name);
      this.$refs.manufacturerFilter.visible = (data.name === this.$refs.manufacturerFilter.name);
      this.$refs.countryFilter.visible = (data.name === this.$refs.countryFilter.name);
    },
    removeTag(tag) {
      this.selectedTypes = this.selectedTypes.filter(e => e !== tag)
      this.selectedCountry = this.selectedCountry.filter(e => e !== tag)
      this.selectedManufacturer = this.selectedManufacturer.filter(e => e !== tag)
      this.selectedCategory = this.selectedCategory.filter(e => e !== tag)
    },
  },
  computed: {
    cssClass2() {
      if(localStorage.getItem('insceneOk') != null)
        return 'back-inscene active'
      else
        return 'back-inscene noactive'
    },
    visibilePag (pageNum) {
      // console.log(pageNum+"количество страниц!!!!");
      if(!(pageNum === null)){
        document.querySelector(".pagination").classList.add("active")
      }else{
        document.querySelector(".pagination").classList.remove("active")
      }
    },
    allObjects() {
      return store.state.expoObjects.map(obj => tr(obj, this.$i18n.locale));
    },
    objects() {
      return this.allObjects.filter(obj => {
        if (this.searchName.length !== 0) {
          if (!obj.name.toLowerCase().includes(this.searchName.toLowerCase()))
            return false;
        }
        if (this.selectedTypes.length !== 0) {
          if (!this.selectedTypes.contains(obj.type))
            return false;
        }
        if (this.selectedCategory.length !== 0) {
          if (!this.selectedCategory.contains(obj.category))
            return false;
        }
        if (this.selectedManufacturer.length !== 0) {
          if (!this.selectedManufacturer.contains(obj.manufacturer))
            return false;
        }
        if (this.selectedCountry.length !== 0) {
          if (!this.selectedCountry.contains(obj.country))
            return false;
        }
        return true;
      });
    },
    paginatedObjects() {
      return this.objects.slice(
        (this.page - 1) * this.paginationOptions.chunk, (this.page - 1) * this.paginationOptions.chunk + this.paginationOptions.chunk
      );

    },
    types() {
      return this.allObjects.map(e => e.type).filter(e => e).unique().sort();
    },
    manufacturers() {
      return this.allObjects.map(e => e.manufacturer).filter(e => e).unique().sort();
    },
    categories() {
      return this.allObjects.map(e => e.category).filter(e => e).unique().sort();
    },
    countries() {
      return this.allObjects.map(e => e.country).filter(e => e).unique().sort();
    },
    allSelectedTags() {
      return this.selectedTypes.concat(this.selectedCategory).concat(this.selectedManufacturer).concat(this.selectedCountry)
    },
  }
}
</script>

<style lang="css">
  .back-inscene.active{
    display: flex;
  }
  .catalog-new{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #7C7C7C;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .catalog-button--new{
    display: flex;
    justify-content: flex-end;
  }
  .save-catalog{
    margin-right: 40px;
  }
  .catalog-new img{
    margin-right: 12px;
  }
  .catalog-new:hover{
    color: #7C7C7C;
  }
  .catalog-mobile--new{
    display: none;
  }
  @media (max-width: 1399px) {
    .back-inscene {
      margin-top: 0px;
    }
  }
  @media (max-width: 1199px) {
    .catalog-button--new{
      display: none;
    }
    .catalog-mobile--new{
      display: flex;
      padding-top: 40px;
      justify-content: space-around;
    }
  }
  @media (max-width: 768px) {
    .back-inscene.active {
      margin-top: 18px;
    }
  }
  @media (max-width: 575px) {
    .catalog-new {
      font-size: 18px;
    }
    .catalog-new img{
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }
  @media (max-width: 468px) {
    .save-catalog {
      margin-right: 0px;
    }
    .catalog-mobile--new {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .studio-btn{
      margin-top: 12px;
    }
  }
</style>
